import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import Seo from '../components/Seo'

import ArticlesRow from '../components/ArticlesRow'

const BlogPage = ({ data }) => (
  <Layout pageData={data.strapiBlog}>
    <Seo title={data.strapiBlog.title} />
    <div className="container">
      <ArticlesRow />
    </div>
  </Layout>
)

export default BlogPage

export const pageQuery = graphql`
  query {
    strapiBlog {
      title
      subtitle
      cover {
        localFile {
          childImageSharp {
            gatsbyImageData(
              transformOptions: {grayscale: true}
            )
          }
        }
      }
    }
  }
`